<template>
  <v-card class="py-5">
    <v-card-title class="justify-center text-h4 pb-10 primary--text"
      >ตั้งค่าเวลาทำการ</v-card-title
    >
    <v-card-text class="popup-background">
      <v-row justify="center">
        <v-col cols="11">
          <v-row
            no-gutters
            align="start"
            v-for="(day, index) in daylist"
            :key="index"
          >
            <v-col cols="2">
              <div class="text-h6 grey--text mt-2">{{ day.name }}</div>
            </v-col>
            <v-col cols="2">
              <v-switch
                v-model="form[day.keyName].open"
                inset
                :label="form[day.keyName].open ? 'เปิด' : 'ปิด'"
                class="mt-2"
              ></v-switch>
            </v-col>
            <v-col cols="8">
              <!-- {{form[`${day.keyName}`]}} -->
              <v-row
                class="mb-5"
                no-gutters
                align="center"
                v-for="(time, index2) in form[day.keyName].time"
                :key="index2"
              >
                <v-col cols="3">
                  <v-text-field
                    placeholder="08:00"
                    v-model="time.start"
                    outlined
                    dense
                    hide-details
                    :error-messages="
                      $v.form[day.keyName].time.$each.$iter[index2].start.$error
                        ? $error_text
                        : ''
                    "
                  />
                </v-col>
                <v-col cols="1" class="text-center">ถึง</v-col>
                <v-col cols="3">
                  <v-text-field
                    placeholder="17:00"
                    v-model="time.end"
                    outlined
                    dense
                    hide-details
                    :error-messages="
                      $v.form[day.keyName].time.$each.$iter[index2].end.$error
                        ? $error_text
                        : ''
                    "
                  />
                </v-col>
                <v-col cols="1">
                  <v-btn
                    icon
                    @click="onClickDelete(day.keyName, index2)"
                    v-if="index2 > 0"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-col>
                <v-col
                  cols="2"
                  v-if="
                    index2 + 1 == form[day.keyName].time.length && index2 < 1
                  "
                >
                  <v-btn color="primary" text @click="onClickAdd(day.keyName)"
                    >+ เพิ่มเวลาทำการ</v-btn
                  >
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions class="py-5">
      <v-spacer />
      <v-btn outlined class="px-5 mr-10" color="grey" @click="close()"
        >ปิด</v-btn
      >
      <v-btn
        class="px-10 mr-15"
        color="primary"
        @click="save()"
        :loading="loading"
        :disabled="loading"
        >บันทึกข้อมูล</v-btn
      >
    </v-card-actions>
  </v-card>
</template>
<script>
import { required } from 'vuelidate/lib/validators';
export default {
  props: {
    item: {
      type: [Array, Object],
      default: null
    }
  },
  validations: {
    form: {
      monday: { time: { $each: { start: {}, end: {} } } },
      tuesday: { time: { $each: { start: {}, end: {} } } },
      wednesday: { time: { $each: { start: {}, end: {} } } },
      thursday: { time: { $each: { start: {}, end: {} } } },
      friday: { time: { $each: { start: {}, end: {} } } },
      saturday: { time: { $each: { start: {}, end: {} } } },
      sunday: { time: { $each: { start: {}, end: {} } } }
    }
  },
  data() {
    return {
      loading: false,
      switch1: true,
      switch2: false,
      form: {
        friday: { open: false, time: [] },
        monday: { open: false, time: [] },
        saturday: { open: false, time: [] },
        sunday: { open: false, time: [] },
        thursday: { open: false, time: [] },
        tuesday: { open: false, time: [] },
        wednesday: { open: false, time: [] }
      },
      time: [],
      daylist: [
        { keyName: 'monday', name: 'วันจันทร์' },
        { keyName: 'tuesday', name: 'วันอังคาร' },
        { keyName: 'wednesday', name: 'วันพุธ' },
        { keyName: 'thursday', name: 'วันพฤหัสบดี' },
        { keyName: 'friday', name: 'วันศุกร์' },
        { keyName: 'saturday', name: 'วันเสาร์' },
        { keyName: 'sunday', name: 'วันอาทิตย์' }
      ]
    };
  },
  created() {
    // this.getData()
    this.form = this.item;
    this.form.monday.time[0].start = '08:00';
    this.form.monday.time[0].end = '17:00';
    this.form.tuesday.time[0].start = '08:00';
    this.form.tuesday.time[0].end = '17:00';
    this.form.wednesday.time[0].start = '08:00';
    this.form.wednesday.time[0].end = '17:00';
    this.form.thursday.time[0].start = '08:00';
    this.form.thursday.time[0].end = '17:00';
    this.form.friday.time[0].start = '08:00';
    this.form.friday.time[0].end = '17:00';
    this.form.saturday.time[0].start = '08:00';
    this.form.saturday.time[0].end = '17:00';
    this.form.sunday.time[0].start = '08:00';
    this.form.sunday.time[0].end = '17:00';
  },
  methods: {
    save() {
      this.$v.form.$touch();
      if (this.$v.form.$invalid) return;

      // this.loading = true;
      this.$emit('onChange', this.form);
      this.$emit('onClose');
    },
    close() {
      this.$emit('onClose');
    },
    open() {
      this.form = this.item;
    },
    onClickDelete(keyName, index) {
      this.form[keyName].time.splice(index, 1);
    },
    onClickAdd(keyName) {
      this.form[keyName].time.push({ end: '', start: '' });
    }
  }
};
</script>
