<template>
  <v-card class="py-5">
    <v-card-title class="justify-center text-h4 pb-10 primary--text">ตั้งค่าบัญชีธนาคาร</v-card-title>
    <v-card-text class="popup-background">
      <v-row justify="center">
        <v-col cols="9">
          <v-row no-gutters align="center" class="title-table-banks py-2 text-center">
            <v-col cols="11">ธนาคาร (ชื่อนี้จะแสดงตอนกดรับเงิน)</v-col>
            <!-- <v-col cols="4">ชื่อบัญชี</v-col>
            <v-col cols="4">เลขที่บัญชี</v-col> -->
            <v-col cols="1"></v-col>
          </v-row>
          <v-row no-gutters align="center" v-for="(data, index) in list" :key="index" class="bgc-white">
            <v-col cols="11">
              <v-text-field v-model="data.bank_name" outlined dense hide-details class="pa-2" />
            </v-col>
            <!-- <v-col cols="4">
              <v-text-field v-model="data.account_name" outlined dense hide-details class="pa-2" />
            </v-col>
            <v-col cols="4">
              <v-text-field v-model="data.account_number" outlined dense hide-details class="pa-2" />
            </v-col> -->
            <v-col cols="1" class="text-center">
              <v-icon @click="remove(data.account_number, index)">mdi-delete</v-icon>
            </v-col>
          </v-row>
          <v-row no-gutters align="center" v-if="list.length == 0" class="text-center py-3 bgc-white">
            <v-col cols="12">กรุณา กดปุ่มเพิ่มธนาคาร</v-col>
          </v-row>
          <v-row class="pt-5">
            <v-col cols="3" class="text-left pt-0">
              <v-btn outlined color="primary" small @click="add"><v-icon left>mdi-plus</v-icon> เพิ่มธนาคาร</v-btn>
            </v-col>
          </v-row>
          <!-- <v-data-table
            class="elevation-2"
            :headers="headers"
            :items="list"
            :loading="loading"
            hide-default-footer
            sort-by="bank_name"
            :loading-text="$table_loading_text"
            >

            <template v-slot:[`item.actions`]="{ item }">
              <v-icon @click="remove(item)">mdi-delete</v-icon>
            </template>

            <template v-slot:footer>

              <v-row class="pl-5 pt-5">

                <v-col cols="3" class="text-center">
                  <v-text-field name="" outlined dense/>
                </v-col>

                <v-col cols="3" class="text-center">
                  <v-text-field name="" outlined dense/>
                </v-col>

                <v-col cols="3" class="text-center">
                  <v-text-field name="" outlined dense/>
                </v-col>

                <v-col cols="3" class="text-center">
                  <v-icon>mdi-delete</v-icon>
                </v-col>

              </v-row>

              <v-row class="pl-5">
                <v-col cols="3" class="text-left pt-0">
                  <v-btn outlined color="primary" small><v-icon left>mdi-plus</v-icon> เพิ่มบัญชี</v-btn>
                </v-col>
              </v-row>

            </template>

          </v-data-table> -->

       
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions class="py-5">
      <v-spacer/>
      <v-btn outlined class="px-5 mr-10" color="grey" @click="close()">ปิด</v-btn>
      <v-btn class="px-10 mr-15" color="primary" @click="save()" :loading="loading" :disabled="loading">บันทึกข้อมูล</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  export default {
    props: {
      item: {
        type: Array,
        default: null,
      },
      delete_bank_account: {
        type: Array,
        default: null,
      },
    },
    data () {
      return {
        loading: false,
        list: [],
        deleteList: [],
      }
    },
    created () {
      this.getData()
    },
    methods: {
      save() {
        this.$emit('onClose');
        this.$emit('onChange', [ ...this.list], [ ...this.deleteList]);
      },
      close() {
        this.$emit('onClose');
      },
      remove: async function (account_number, index) {
        await this.deleteList.push(account_number);
        this.list.splice(index, 1);
      },
      add() {
        this.list.push({bank_name:"", account_name: "", account_number: ""});
      },
      getData () {
        this.list = [ ...this.item];
        this.deleteList = [ ...this.delete_bank_account];
      },
    },
  }
</script>

<style lang="scss" scoped>
.title-table-banks {
  background-color: #F5F6FA;
}
</style>

